<template>
    <content-view class="w-100">
        <div class="fav-materials">
            <template v-if="favMaterials.length">
                <div class="fav-materials__header">
                    <emoji class="mr-20" emoji="star" set="apple" :size="32" />
                    <h2 class="fav-materials__title">
                        Избранные материалы
                    </h2>
                </div>
                <ul class="fav-materials__list">
                    <li
                        :key="material.id"
                        v-for="material in favMaterials"
                        class="fav-materials__item"
                    >
                        <emoji
                            v-if="material.emoji.length"
                            :emoji="material.emoji"
                            set="apple"
                            :size="16"
                        />
                        <icon v-else width="15" height="18" class="fav-materials__item-icon">
                            <svg
                                width="15"
                                height="18"
                                viewBox="0 0 15 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 0.5C0 0.223858 0.223858 0 0.5 0H14.5C14.7761 0 15 0.223858 15 0.5V17.5C15 17.7761 14.7761 18 14.5 18H0.5C0.223858 18 0 17.7761 0 17.5V0.5ZM2.99988 10.1252C2.99988 9.50392 3.50356 9.00024 4.12488 9.00024H10.8749C11.4962 9.00024 11.9999 9.50392 11.9999 10.1252C11.9999 10.7466 11.4962 11.2502 10.8749 11.2502H4.12488C3.50356 11.2502 2.99988 10.7466 2.99988 10.1252ZM4.12488 4.50012C3.50356 4.50012 2.99988 5.0038 2.99988 5.62512C2.99988 6.24644 3.50356 6.75012 4.12488 6.75012H7.87488C8.4962 6.75012 8.99988 6.24644 8.99988 5.62512C8.99988 5.0038 8.4962 4.50012 7.87488 4.50012H4.12488Z"
                                    fill="#989CAE"
                                />
                            </svg>
                        </icon>
                        <router-link
                            class="fav-materials__link"
                            :to="`/knowledge/article/${material.id}?from=FavouritesMaterials`"
                            >{{ material.title }}</router-link
                        >
                    </li>
                </ul>
            </template>
            <template v-else>
                <loading-spinner v-if="isLoading" />
                <div v-else class="plug">
                    <div class="plug__container">
                        <icon width="160" height="160">
                            <path
                                d="M77 0L101.546 46.2155L153.085 55.2786L116.716 92.9045L124.023 144.721L77 121.76L29.9772 144.721L37.2839 92.9045L0.915482 55.2786L52.4541 46.2155L77 0Z"
                                fill="#E6EBF3"
                            />
                        </icon>
                        <span class="v-desc"
                            >В избранном пока нет материалов</span
                        >
                    </div>
                </div>
            </template>
        </div>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import Icon from '@/components/icon/Icon'
import session from '@/api/session'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { Emoji } from 'emoji-mart-vue'
import { metaTitle } from '@/mixins/meta_title'
export default {
  name: 'FavouritesMaterials',
  components: { Icon, ContentView, Emoji, LoadingSpinner },
  data () {
    return {
      favMaterials: [],
      isLoading: true
    }
  },
  mixins: [metaTitle],
  async created () {
    this.isLoading = true
    const request = await session.get(
      '/api/v1/knowledge-base/favorite/'
    )
    this.favMaterials = request.data
    this.isLoading = false
  }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";
.fav-materials {
    max-width: 960px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    @include below(1280px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    &__header {
        display: flex;
        align-items: baseline;
    }
    &__list {
        margin-top: 50px;
    }
    &__item {
        display: flex;
        align-items: center;
        &:nth-child(n + 2) {
            margin-top: 20px;
        }
        &-icon {
            min-width: 15px;
        }
    }
    &__link {
        margin-left: 10px;
        font-size: 1.25rem;
        color: #000;
        font-weight: 600;
        &:hover {
            text-decoration: underline;
        }
    }
    &__title {
        font-size: 2.5rem;
    }
}

.plug {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__container {
        height: calc(100vh - 25px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 225px;
        & > span {
            margin-top: 24px;
            text-align: center;
            font-size: 24px;
            line-height: 32px;
        }
    }
}
</style>
